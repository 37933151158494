const {
    last
} = require("lodash");

var isDirty = false;
var index_new_years = -1;
var index_new_contacts = -1;
var index_new_choirs = -1;
var index_new_sponsors = -1;
var index_new_repertoire = -2;
var index_new_eventconcertrows = -1;
var index_new_eventschedulerows = -1;

window.onbeforeunload = function () {
    if (isDirty) {
        return "Die Aenderungen wurden noch nicht gespeichert!";
    }
}
$(document).ready(function () {

    // Prevent accidental navigation away
    $('#contact-form input, #contact-form select, #contact-form checkbox, #contact-form textarea, #choir-form input, #choir-form select, #choir-form checkbox, #choir-form textarea, #location-form input, #location-form select, #location-form checkbox, #location-form textarea, #event-form input, #event-form select, #event-form checkbox, #event-form textarea, #fundraising-form input, #fundraising-form select, #fundraising-form checkbox, #fundraising-form textarea').bind("change", function () {
        isDirty = true;
        //console.log("isDirty");
    });
    $('#contact-form, #choir-form, #location-form, #event-form, #fundraising-form').submit(function () {
        isDirty = false;
        return true;
    });

    /* --------------------------------- */

    /*$('textarea').on('input', function () {
        this.style.height = 'auto';

        this.style.height =
            (this.scrollHeight + 10) + 'px';
    });

    setTimeout(function () {
        $("textarea").each(function () {
            console.log($(this).prop('scrollHeight'));
            this.style.height =
                (this.scrollHeight + 10) + 'px';
        });
    }, 500);*/

    /* --------------------------------- */

    $('#choir-form').on('click', '.add-year', function () {
        var elem = $(this);
        $.ajax("/year/getform/" + choir_id + "/" + index_new_years).done(function (data) {
            elem.before(data);
        });
        index_new_years--;
    });

    $('#choir-form').on('mousedown', '.delete-year', function () {
        var div = $(this).parent().parent().parent();
        div.css('background-color', 'rgba(255, 0, 0, 0.3)');
    });

    $('#choir-form').on('click', '.delete-year', function () {
        var div = $(this).parent().parent().parent();
        var response = confirm("Wollen Sie dieses Feld wirklich löschen?");
        if (response == true) {
            var year_id = div.children('input[name=year_id]').val();
            if (year_id !== undefined && year_id > 0) {
                div.after('<input type="hidden" name="years_delete[]" value="' + year_id + '">');
            }
            div.fadeOut(400, function () {
                div.remove();
            });
        } else {
            div.css('background-color', 'transparent');
        }
        return false;
    });

    /* --------------------------------- */

    /* --------------------------------- */

    $('#choir-form').on('click', '.add-contact', function () {
        var elem = $(this);
        $.ajax("/contact/getchoircontactform/" + choir_id + "/" + index_new_contacts).done(function (data) {
            elem.before(data);
        });
        index_new_contacts--;
        setTimeout(function () {
            initializeSelect2();
        }, 100);
    });

    $('#choir-form').on('mousedown', '.delete-contact', function () {
        var div = $(this).parent().parent().parent();
        div.css('background-color', 'rgba(255, 0, 0, 0.3)');
    });

    $('#choir-form').on('click', '.delete-contact', function () {
        var div = $(this).parent().parent().parent();
        var response = confirm("Wollen Sie dieses Feld wirklich löschen?");
        if (response == true) {
            var contact_id = div.children('input[name=contact_id]').val();
            if (contact_id !== undefined && contact_id > 0) {
                div.after('<input type="hidden" name="contacts_delete[]" value="' + contact_id + '">');
            }
            div.fadeOut(400, function () {
                div.remove();
            });
        } else {
            div.css('background-color', 'transparent');
        }
        return false;
    });

    /* --------------------------------- */


    // show/hide other field in choir contacts
    $('#choir-form').on('change', '.contactable_function_id', function () {
        var contactable_other = $(this).parent().next('.contactable_other_div').children('.contactable_other');
        if ($(this).val() == 87) {
            contactable_other.show();
        } else {
            contactable_other.hide();
        }
    });

    /* --------------------------------- */

    $('#fundraising-form').on('click', '.add-year', function () {
        var elem = $(this);
        $.ajax("/fundraisingyear/getform/" + fundraising_id + "/" + index_new_years).done(function (data) {
            elem.before(data);
        });
        index_new_years--;
        setTimeout(function () {
            initializeNewSelect2(elem);
        }, 100);
    });

    $('#fundraising-form').on('mousedown', '.delete-year', function () {
        var div = $(this).parent().parent().parent();
        div.css('background-color', 'rgba(255, 0, 0, 0.3)');
    });

    $('#fundraising-form').on('click', '.delete-year', function () {
        var div = $(this).parent().parent().parent();
        var response = confirm("Wollen Sie dieses Feld wirklich löschen?");
        if (response == true) {
            var year_id = div.children('input[name=year_id]').val();
            if (year_id !== undefined && year_id > 0) {
                div.after('<input type="hidden" name="years_delete[]" value="' + year_id + '">');
            }
            div.fadeOut(400, function () {
                div.remove();
            });
        } else {
            div.css('background-color', 'transparent');
        }
        return false;
    });

    /* --------------------------------- */

    $('#event-form').on('click', '.add-choir', function () {
        var elem = $(this);
        $.ajax("/event/getchoirform/" + event_id + "/" + index_new_choirs).done(function (data) {
            elem.before(data);
        });
        index_new_choirs--;
        setTimeout(function () {
            initializeNewSelect2(elem);
        }, 100);
    });

    $('#event-form').on('mousedown', '.delete-choir', function () {
        var div = $(this).parent().parent().parent();
        div.css('background-color', 'rgba(255, 0, 0, 0.3)');
    });

    $('#event-form').on('click', '.delete-choir', function () {
        var div = $(this).parent().parent().parent();
        var response = confirm("Wollen Sie dieses Feld wirklich löschen?");
        if (response == true) {
            var choir_id = div.children('input[name=choir_id]').val();
            if (choir_id !== undefined && choir_id > 0) {
                div.after('<input type="hidden" name="choirs_delete[]" value="' + choir_id + '">');
            }
            div.fadeOut(400, function () {
                div.remove();
            });
        } else {
            div.css('background-color', 'transparent');
        }
        return false;
    });

    if ($('#choirs-sortable').length) {
        $("#choirs-sortable").sortable();
    }
    /* --------------------------------- */

    /* --------------------------------- */

    $('#event-form').on('click', '.add-sponsor', function () {
        var elem = $(this);
        $.ajax("/event/getsponsorform/" + event_id + "/" + index_new_sponsors).done(function (data) {
            elem.before(data);
        });
        index_new_sponsors--;
        setTimeout(function () {
            initializeNewSelect2(elem);
        }, 100);
    });

    $('#event-form').on('mousedown', '.delete-sponsor', function () {
        var div = $(this).parent().parent().parent();
        div.css('background-color', 'rgba(255, 0, 0, 0.3)');
    });

    $('#event-form').on('click', '.delete-sponsor', function () {
        var div = $(this).parent().parent().parent();
        var response = confirm("Wollen Sie dieses Feld wirklich löschen?");
        if (response == true) {
            var sponsor_id = div.children('input[name=sponsor_id]').val();
            if (sponsor_id !== undefined && sponsor_id > 0) {
                div.after('<input type="hidden" name="sponsors_delete[]" value="' + sponsor_id + '">');
            }
            div.fadeOut(400, function () {
                div.remove();
            });
        } else {
            div.css('background-color', 'transparent');
        }
        return false;
    });

    if ($('#sponsors-sortable').length) {
        $("#sponsors-sortable").sortable();
    }
    /* --------------------------------- */

    /* --------------------------------- */

    $('#repertoire-form').on('click', '.add-repertoire', function () {
        var elem = $(this);
        $.ajax("/repertoire/getform/" + year_id + "/" + index_new_repertoire).done(function (data) {
            elem.before(data);
        });
        index_new_repertoire--;
    });

    $('#repertoire-form').on('mousedown', '.delete-repertoire', function () {
        var div = $(this).parent().parent().parent();
        div.css('background-color', 'rgba(255, 0, 0, 0.3)');
    });

    $('#repertoire-form').on('click', '.delete-repertoire', function () {
        var div = $(this).parent().parent().parent();
        var response = confirm("Really delete this line?");
        if (response == true) {
            var repertoire_id = div.children('input[name=repertoire_id]').val();
            if (repertoire_id !== undefined && repertoire_id > 0) {
                div.after('<input type="hidden" name="repertoire_delete[]" value="' + repertoire_id + '">');
            }
            div.fadeOut(400, function () {
                div.remove();
            });
        } else {
            div.css('background-color', 'transparent');
        }
        return false;
    });

    /* --------------------------------- */

    /* --------------------------------- */

    $('#event-form').on('click', '.add-eventconcertrow', function () {
        var elem = $(this);
        var parentElement = elem.closest('.eventconcertrow-group');
        $.ajax("/eventconcertrow/getform/" + event_id + "/" + index_new_eventconcertrows).done(function (data) {
            if (parentElement.length > 0) {
                $(parentElement).after(data);
            } else {
                $('#eventconcertrows-sortable').append(data);
            }
        });
        index_new_eventconcertrows--;
    });

    $('#event-form').on('mousedown', '.delete-eventconcertrow', function () {
        var div = $(this).parent().parent().parent();
        div.css('background-color', 'rgba(255, 0, 0, 0.3)');
    });

    $('#event-form').on('click', '.delete-eventconcertrow', function () {
        var div = $(this).parent().parent().parent();
        var response = confirm("Wollen Sie dieses Feld wirklich löschen?");
        if (response == true) {
            var eventconcertrow_id = div.children('input[name=eventconcertrow_id]').val();
            if (eventconcertrow_id !== undefined && eventconcertrow_id > 0) {
                div.after('<input type="hidden" name="eventconcertrows_delete[]" value="' + eventconcertrow_id + '">');
            }
            div.fadeOut(400, function () {
                div.remove();
            });
        } else {
            div.css('background-color', 'transparent');
        }
        return false;
    });

    /* --------------------------------- */

    if ($('#eventconcertrows-sortable').length) {
        $("#eventconcertrows-sortable").sortable();
    }

    $(document).on('change', '.eventconcertrow-duration', function () {
        updateEventconcertrowDuration();
    });
    $(document).on('change', '#rows\\[konzertbeginn\\]\\[text1\\]\\[\\]', function () {
        updateEventconcertrowDuration();
    });

    function updateEventconcertrowDuration() {
        var sum = 0;
        $('.eventconcertrow-duration').each(function () {
            sum += +$(this).val();
        });
        $('#eventconcertrows-totalduration').html(sum);
        var time = $("#rows\\[konzertbeginn\\]\\[text1\\]\\[\\]").val();
        var newTime = new Date(new Date("1970/01/01 " + time).getTime() + sum * 60000).toLocaleTimeString('en-UK', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
        $('.eventconcertrows-endofconcert').html(newTime);
    };

    updateEventconcertrowDuration();

    /* --------------------------------- */

    $('#event-form').on('click', '.add-eventschedulerow-before', function () {
        var elem = $(this);
        var parentElement = elem.closest('.eventschedulerow-group');
        var div = $('#eventschedulerows-before-sortable .eventschedulerow-group').last();
        if (div.length) {
            var date = div.find('.eventschedulerow-date').val();
            //var endtime = div.find('.eventschedulerow-endtime').val();
            //var newstarttime = getNewStartTime(endtime);
        }
        $.ajax("/eventschedulerow/getform/" + event_id + "/" + index_new_eventschedulerows + "/" + 0).done(function (data) {
            data = data.replace('add-eventschedulerow-', 'add-eventschedulerow-before');
            if (parentElement.length > 0) {
                $(parentElement).after(data);
            } else {
                $('#eventschedulerows-before-sortable').append(data);
            }
            index_new_eventschedulerows--;
            //setTimeout(function () {
            initializeNewSelect2(elem);
            if (div.length) {
                $('#eventschedulerows-before-sortable .eventschedulerow-group').last().find('.eventschedulerow-date').val(date);
                //$('#eventschedulerows-before-sortable .eventschedulerow-group').last().find('.eventschedulerow-starttime').val(newstarttime);
                //$('#eventschedulerows-before-sortable .eventschedulerow-group').last().find('.eventschedulerow-endtime').val(newstarttime);
            }
            //}, 100);
        });

    });

    $('#event-form').on('mousedown', '.delete-eventschedulerow', function () {
        var div = $(this).parent().parent().parent();
        div.css('background-color', 'rgba(255, 0, 0, 0.3)');
    });

    $('#event-form').on('click', '.delete-eventschedulerow', function () {
        var div = $(this).parent().parent().parent();
        var response = confirm("Wollen Sie dieses Feld wirklich löschen?");
        if (response == true) {
            var eventschedulerow_id = div.children('input[name=eventschedulerow_id]').val();
            if (eventschedulerow_id !== undefined && eventschedulerow_id > 0) {
                div.after('<input type="hidden" name="eventschedulerows_delete[]" value="' + eventschedulerow_id + '">');
            }
            div.fadeOut(400, function () {
                div.remove();
            });
        } else {
            div.css('background-color', 'transparent');
        }
        return false;
    });

    function getNewStartTime(endtime) {
        var timeObject = new Date("2000-01-01 " + endtime);
        timeObject.setTime(timeObject.getTime() + 1000 * 60);
        return ('0' + timeObject.getHours()).slice(-2) + ':' + ('0' + timeObject.getMinutes()).slice(-2);
    }
    /* --------------------------------- */

    if ($('#eventschedulerows-before-sortable').length) {
        $("#eventschedulerows-before-sortable").sortable();
    }

    /* --------------------------------- */

    $('#event-form').on('click', '.add-eventschedulerow-after', function () {
        var elem = $(this);
        var parentElement = elem.closest('.eventschedulerow-group');
        var div = $('#eventschedulerows-after-sortable .eventschedulerow-group').last();
        if (div.length) {
            var date = div.find('.eventschedulerow-date').val();
            //var endtime = div.find('.eventschedulerow-endtime').val();
            //var newstarttime = getNewStartTime(endtime);
        }
        $.ajax("/eventschedulerow/getform/" + event_id + "/" + index_new_eventschedulerows + "/" + 1).done(function (data) {
            data = data.replace('add-eventschedulerow-', 'add-eventschedulerow-after');
            if (parentElement.length > 0) {
                $(parentElement).after(data);
            } else {
                $('#eventschedulerows-after-sortable').append(data);
            }
            index_new_eventschedulerows--;
            //setTimeout(function () {
            initializeNewSelect2(elem);
            if (div.length) {
                $('#eventschedulerows-after-sortable .eventschedulerow-group').last().find('.eventschedulerow-date').val(date);
                //$('#eventschedulerows-after-sortable .eventschedulerow-group').last().find('.eventschedulerow-starttime').val(newstarttime);
                //$('#eventschedulerows-after-sortable .eventschedulerow-group').last().find('.eventschedulerow-endtime').val(newstarttime);
            }
            //}, 100);
        });

    });

    /*
    // same as eventschedulerow-before
    $('#event-form').on('mousedown', '.delete-eventschedulerow', function () {
        var div = $(this).parent().parent().parent();
        div.css('background-color', 'rgba(255, 0, 0, 0.3)');
    });

    $('#event-form').on('click', '.delete-eventschedulerow', function () {
        var div = $(this).parent().parent().parent();
        var response = confirm("Wollen Sie dieses Feld wirklich löschen?");
        if (response == true) {
            var eventschedulerow_id = div.children('input[name=eventschedulerow_id]').val();
            if (eventschedulerow_id !== undefined && eventschedulerow_id > 0) {
                div.after('<input type="hidden" name="eventschedulerows_delete[]" value="' + eventschedulerow_id + '">');
            }
            div.fadeOut(400, function () {
                div.remove();
            });
        } else {
            div.css('background-color', 'transparent');
        }
        return false;
    });*/

    /* --------------------------------- */

    // make eventschedulerows sortable
    if ($('#eventschedulerows-after-sortable').length) {
        $("#eventschedulerows-after-sortable").sortable();
    }

    // save the active tab in hidden input
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (event) {
        var tab_id = $(this).attr('id');
        tab_id = tab_id.replace('-tab', '');
        $('#tab').val(tab_id);
    });

    $(".alert-dismissible").fadeTo(1000, 500).slideUp(500, function () {
        $(".alert-dismissible").alert('close');
    });

});
